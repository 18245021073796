import React, { ReactNode } from 'react';

interface MetricCardProps {
  title: string;
  value: string | number;
  icon: ReactNode;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, icon }) => {
  return (
    <div className="bg-gradient-to-br from-blue-100 to-blue-200 hover:from-blue-200 hover:to-blue-300 rounded-lg p-4 shadow-md border border-blue-300 w-[200px] transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1 cursor-pointer group">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium text-blue-700 group-hover:text-blue-800">{title}</h3>
        {icon}
      </div>
      <p className="mt-1 text-2xl font-semibold text-blue-900 group-hover:scale-105 transform transition-transform">
        {value}
      </p>
    </div>
  );
};

export default MetricCard;
