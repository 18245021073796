import { Checkbox } from '@fluentui/react-components'

export interface GroupPreferenceProps {
  id: string
  name: string
  isUserSubscribed: boolean
  onChangePreference: (id: string, isUserSubscribed: boolean) => Promise<void>
}

export default function GroupPreference(props: GroupPreferenceProps) {
  const checkboxStyles: React.CSSProperties = {
    '--colorNeutralBackground1Selected': '#22C55E',
    '--colorNeutralStrokeAccessible': '#22C55E',
    '--colorNeutralForeground1': '#FFFFFF',
    '--colorNeutralBackgroundSelected': '#22C55E',
    '--colorCompoundBrandBackground': '#22C55E',
    '--colorCompoundBrandBackgroundHover': '#22C55E',
    '--colorNeutralForegroundInverted': '#FFFFFF',
    '--colorBrandBackgroundSelected': '#22C55E',
    '--colorNeutralStrokeAccessibleHover': '#22C55E',
    '--colorNeutralBackground1Hover': '#22C55E',
    '--colorNeutralBackgroundHover': '#22C55E',
    '--colorNeutralForegroundInvertedHover': '#FFFFFF',
    '--colorBrandBackgroundHover': '#22C55E',
    '--colorNeutralForegroundInvertedPressed': '#FFFFFF',
    '--colorNeutralBackgroundPressed': '#22C55E',
    '--colorNeutralBackground1Pressed': '#22C55E',
    '--colorBrandBackgroundPressed': '#22C55E',
    '--colorNeutralStrokeAccessiblePressed': '#22C55E',
    '--checkbox-background-checked': '#22C55E',
    '--checkbox-border-checked': '#22C55E',
    '--checkbox-indicator-color': '#ffffff',
    '--checkbox-hover-indicator-color': '#ffffff',
    '--checkbox-pressed-indicator-color': '#ffffff',
    '--checkbox-checked-indicator-color': '#ffffff',
    '--checkbox-hover-background': '#22C55E20',
    '--checkbox-hover-border': '#22C55E',
    '--checkbox-pressed-background': '#22C55E40',
    '--checkbox-pressed-border': '#22C55E',
    '--checkbox-checked-hover-background': '#22C55E',
    '--checkbox-checked-hover-indicator-color': '#ffffff',
    '--checkbox-checked-pressed-background': '#22C55E',
    '--checkbox-checked-pressed-indicator-color': '#ffffff',
    'transition': 'all 0.3s ease-in-out',
    'transform': props.isUserSubscribed ? 'scale(1.1)' : 'scale(1)',
    'animation': props.isUserSubscribed ? 'checkmark-pop 0.3s ease-in-out' : 'none',
    'filter': props.isUserSubscribed ? 'drop-shadow(0 0 2px rgba(255, 255, 255, 0.5))' : 'none',

  } as React.CSSProperties;
  return (
    <div
      id={`interest-${props.id}`}
      className={`
      cursor-pointer 
      rounded-md 
      mt-3
      p-1 
      transition-all 
      duration-500 
      transform 
      hover:-translate-y-0.5 
      flex 
      items-center 
      gap-1
      w-fit
      min-w-[170px]
      bg-blue-50 text-blue-600 shadow-md animate__animated animate__fadeIn animate__pulse hover:bg-blue-100 hover:shadow-lg
      
    `}
      onClick={() => {
        const element = document.getElementById(`interest-${props.id}`);
        if (element) {
          element.classList.add(props.isUserSubscribed ? 'animate__bounceOutLeft' : 'animate__bounceInRight');
          setTimeout(() => {
            props.onChangePreference(props.id, !props.isUserSubscribed);
            element.classList.remove(props.isUserSubscribed ? 'animate__bounceOutLeft' : 'animate__bounceInRight');
          }, 300);
        }
      }}
    >
      <div className="flex items-center gap-2 w-full min-w-[120px]">
        <span className="flex-shrink-0 animate__animated animate__fadeIn">
          <Checkbox
            checked={props.isUserSubscribed}
            style={checkboxStyles}
          />
        </span>
        <span className="text-sm font-medium break-words line-clamp-4 flex-2 pr-1">{props.name}</span>
      </div>
    </div >
  );
}