import React, { useState } from 'react';
import { FileDown, FileText, Table, FileJson, Loader2 } from 'lucide-react';
import { Menu } from '@headlessui/react';
import {
  exportLeaderboardToPDF,
  exportLeaderboardToExcel,
  exportLeaderboardToCSV,
  exportLeaderboardToJSON
} from '../../../utils/leaderboardExport';

interface LeaderboardExportProps {
  data: {
    rank: number;
    name: string;
    group: string;
    acceptedScore: string;
    participationScore: string;
    acceptedButNotParticipated: string;
    aARatio: string;
    engagementScore: string;
  }[];
  userMetrics: LeaderboardExportProps['data'];
  metrics: {
    totalEvents: number;
    totalParticipants: number;
    avgAcceptance: number;
    avgAttendance: number;
  };
}

const LeaderboardExport: React.FC<LeaderboardExportProps> = ({ data, userMetrics, metrics }) => {
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);

  const handleExport = async (
    exportFn: (
      data: LeaderboardExportProps['data'],
      userMetrics: LeaderboardExportProps['data'],
      metrics: LeaderboardExportProps['metrics']
    ) => void | Promise<void>,
    format: string
  ) => {
    try {
      setIsExporting(true);
      setExportError(null);
      await exportFn(data, userMetrics, metrics);
    } catch (error) {
      console.error(`Error exporting to ${format}:`, error);
      setExportError(`Failed to export as ${format}. Please try again.`);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="relative">
      <Menu>
        <Menu.Button
          className={`inline-flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isExporting ? 'opacity-75 cursor-not-allowed' : ''
            }`}
          disabled={isExporting}
        >
          {isExporting ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <FileDown className="w-4 h-4" />
          )}
          Export
        </Menu.Button>

        <Menu.Items className="absolute right-0 z-50 w-48 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
          {exportError && (
            <div className="px-3 py-2 mx-2 my-1 text-xs text-red-600 bg-red-50 rounded-md">
              {exportError}
            </div>
          )}

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleExport(exportLeaderboardToPDF, 'PDF')}
                className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-700 w-full ${active ? 'bg-gray-100' : ''
                  }`}
                disabled={isExporting}
              >
                <FileText className="w-4 h-4" />
                Export as PDF
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleExport(exportLeaderboardToExcel, 'Excel')}
                className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-700 w-full ${active ? 'bg-gray-100' : ''
                  }`}
                disabled={isExporting}
              >
                <Table className="w-4 h-4" />
                Export as Excel
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleExport(exportLeaderboardToCSV, 'CSV')}
                className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-700 w-full ${active ? 'bg-gray-100' : ''
                  }`}
                disabled={isExporting}
              >
                <FileText className="w-4 h-4" />
                Export as CSV
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleExport(exportLeaderboardToJSON, 'JSON')}
                className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-700 w-full ${active ? 'bg-gray-100' : ''
                  }`}
                disabled={isExporting}
              >
                <FileJson className="w-4 h-4" />
                Export as JSON
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default LeaderboardExport;
