import { Divider } from '@fluentui/react-components'
import { Group } from '../../store'
import _ from 'lodash'
import GroupPreference from './GroupPreference'

export interface GroupPreferencesProps {
  groups: Group[]
  onChangePreference: (id: string, isUserSubscribed: boolean) => Promise<void>
}

export default function GroupPreferences(props: GroupPreferencesProps) {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-x-2 gap-y-0">
        {props.groups &&
          _.sortBy(props.groups, [(group) => !group.isUserSubscribed, (group) => group.name]).map(
            (group: Group, index: number) => (
              <div key={group.id}>
                <GroupPreference
                  id={group.id}
                  name={group.name}
                  isUserSubscribed={group.isUserSubscribed}
                  onChangePreference={props.onChangePreference}
                ></GroupPreference>
                {props.groups.length !== index + 1 && (
                  <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
                )}

                {index + 1 === props.groups.filter((x, _) => x.isUserSubscribed).length && <p></p>}
              </div>
            )
          )}
      </div>
    </>
  )
}
