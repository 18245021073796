import React, { useState, useMemo, useRef } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { MultiValue, ActionMeta, GroupBase } from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartData,
  ChartOptions,
  Chart,
  PieController,
  Colors,
} from 'chart.js';
import { IMeetingInfo } from '../../types';
import {
  createBarData,
  createBarOptions,
  createPieData,
  createPieOptions,
} from './charts/chartConfig';
import { externalLabelsPlugin } from './charts/plugins/ExternalLabelsPlugin';
import ViewToggle from './ViewToggle';
import Leaderboard from './leaderboard/Leaderboard';
import ChartTypeToggle from './charts/ChartTypeToggle';
import { motion, AnimatePresence } from 'framer-motion';
// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PieController,
  Title,
  Tooltip,
  Legend,
  Colors
);
interface GraphViewProps {
  meetings: IMeetingInfo[];
}
type SelectOption = {
  value: string;
  label: string;
};
const GraphView: React.FC<GraphViewProps> = ({ meetings }) => {
  const [selectedMeeting, setSelectedMeeting] = useState<IMeetingInfo | null>(null);
  const [viewType, setViewType] = useState<'bar' | 'pie' | 'leaderboard'>('bar');
  const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
  const [selectedNames, setSelectedNames] = useState<MultiValue<SelectOption>>([]);
  const [selectedGroups, setSelectedGroups] = useState<MultiValue<SelectOption>>([]);

  const handleNameFilterChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    setSelectedNames(newValue);
  };

  const handleGroupFilterChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    setSelectedGroups(newValue);
  };

  const chartRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const uniqueColors = [
    { bg: '#4F46E5', text: '#ffffff' }, // Indigo
    { bg: '#0EA5E9', text: '#ffffff' }, // Sky
    { bg: '#8B5CF6', text: '#ffffff' }, // Purple
    { bg: '#EC4899', text: '#ffffff' }, // Pink
    { bg: '#6366F1', text: '#ffffff' }, // Indigo
    { bg: '#A855F7', text: '#ffffff' }, // Purple
    { bg: '#14B8A6', text: '#ffffff' }, // Teal
    { bg: '#F43F5E', text: '#ffffff' }, // Rose
    { bg: '#8B5CF6', text: '#ffffff' }, // Violet
    { bg: '#06B6D4', text: '#ffffff' }, // Cyan
  ];
  const barData = createBarData(meetings);
  const barOptions = createBarOptions(meetings);
  const pieData = createPieData(meetings);
  const pieOptions = createPieOptions(meetings);
  const topics = Array.from(new Set(meetings.map((m) => m.interestName)));
  const topicOptions = topics.map((topic, index) => ({
    value: topic,
    label: topic,
    color: {
      bg: uniqueColors[index % uniqueColors.length].bg,
      text: uniqueColors[index % uniqueColors.length].text,
    },
  }));
  const filteredMeetings =
    selectedTopics.length > 0
      ? meetings.filter((meeting) =>
        selectedTopics.some((topic) => topic.value === meeting.interestName)
      )
      : meetings;
  const attendeeStats = useMemo(() => {
    const stats = new Map<string, {
      name: string;
      email: string;
      topic: string;
      totalAttendanceTime: number;
      totalMeetings: number;
      attendedMeetings: number;
      acceptedMeetings: number;
    }>();
    filteredMeetings.forEach(meeting => {
      meeting.attendees.forEach(attendee => {
        const key = attendee.attendeeEmail;
        const current = stats.get(key) || {
          name: attendee.attendeeName,
          email: attendee.attendeeEmail,
          topic: meeting.interestName,
          totalAttendanceTime: 0,
          totalMeetings: 0,
          attendedMeetings: 0,
          acceptedMeetings: 0
        };
        current.totalMeetings++;
        if (attendee.didAttend) {
          current.attendedMeetings++;
          current.totalAttendanceTime += parseInt(attendee.totalAttendanceInSeconds);
        }
        if (attendee.didAccept) {
          current.acceptedMeetings++;
        }
        stats.set(key, current);
      });
    });
    return Array.from(stats.values())
      .sort((a, b) => {
        const aRate = a.attendedMeetings / a.totalMeetings;
        const bRate = b.attendedMeetings / b.totalMeetings;
        if (bRate !== aRate) return bRate - aRate;
        return b.totalAttendanceTime - a.totalAttendanceTime;
      });
  }, [filteredMeetings]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
    >
      <div className="space-y-6">
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <ViewToggle view={viewType} onViewChange={setViewType} />
          <div className="flex items-center gap-4">
            {/* Add any additional controls here */}
          </div>
        </div>

        {viewType !== 'leaderboard' && (
          <motion.div
            key={viewType}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="relative h-[600px] w-full bg-white p-4 rounded-lg shadow-lg"
          >
            {viewType === 'bar' ? (
              <Bar
                ref={chartRef}
                data={barData}
                options={barOptions}
                key="bar-chart"
              />
            ) : viewType === 'pie' ? (
              <div className="flex items-center justify-center h-full">
                <div className="w-[95%] h-[100%]">
                  <Pie
                    ref={chartRef}
                    data={pieData}
                    options={pieOptions}
                    key="pie-chart"
                    plugins={[externalLabelsPlugin]}
                  />
                </div>
              </div>
            ) : null}
          </motion.div>
        )}
        {viewType === 'leaderboard' && (
          <motion.div
            key="leaderboard"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Leaderboard
              meetings={meetings}
              attendeeStats={attendeeStats}
              currentView="leaderboard"
              selectedNames={selectedNames}
              selectedGroups={selectedGroups}
              onNameFilterChange={handleNameFilterChange}
              onGroupFilterChange={handleGroupFilterChange}
            />
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};
export default GraphView;