import React from 'react';
import { BarChart2, PieChart, List } from 'lucide-react';

interface ViewToggleProps {
  view: 'bar' | 'pie' | 'leaderboard';
  onViewChange: (view: 'bar' | 'pie' | 'leaderboard') => void;
}

const ViewToggle: React.FC<ViewToggleProps> = ({ view, onViewChange }) => {
  return (
    <div className="flex items-center justify-start">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          onClick={() => onViewChange('bar')}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-medium border ${view === 'bar'
            ? 'bg-indigo-600 text-white border-indigo-600 hover:bg-indigo-700'
            : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            } rounded-l-md focus:z-10 focus:outline-none`}
        >
          <BarChart2 className="w-4 h-4 mr-2" />
          Bar Chart
        </button>
        <button
          onClick={() => onViewChange('pie')}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-medium border-t border-b ${view === 'pie'
            ? 'bg-indigo-600 text-white border-indigo-600 hover:bg-indigo-700'
            : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            } focus:z-10 focus:outline-none`}
        >
          <PieChart className="w-4 h-4 mr-2" />
          Pie Chart
        </button>
        <button
          onClick={() => onViewChange('leaderboard')}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-medium border ${view === 'leaderboard'
            ? 'bg-indigo-600 text-white border-indigo-600 hover:bg-indigo-700'
            : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            } rounded-r-md focus:z-10 focus:outline-none`}
        >
          <List className="w-4 h-4 mr-2" />
          Leaderboard
        </button>
      </div>
    </div>
  );
};

export default ViewToggle;