import React, { useState, useRef, useId, useEffect, useCallback, useLayoutEffect } from 'react';
import { MoreVertical, Clock, Users, Calendar, Pointer, UserCheck, ChevronDown } from 'lucide-react';
import { IMeetingInfo } from '../../types';
import AvatarGroupPopover from './AvatarGroupPopover';
import DownloadMenu from './DownloadMenu';
import { createPortal } from 'react-dom';

interface AvatarGroupProps {
  meetingInfo: IMeetingInfo;
  colorIndex: number;
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({ meetingInfo, colorIndex }) => {
  const uniqueId = useId();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAvatars, setShowAvatars] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<'total' | 'attended' | null>(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isMenuOpen) return;

      const target = event.target as HTMLElement;
      const isDownloadAction = target.closest('[data-download-action]');
      const isMenuButton = buttonRef.current?.contains(target);
      const isMenuContent = menuRef.current?.contains(target);

      if (isDownloadAction) {
        return; // Don't close if clicking a download action
      }

      if (!isMenuButton && !isMenuContent) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  const updateMenuPosition = useCallback(() => {
    if (!buttonRef.current || !isMenuOpen) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const menuWidth = 180;

    // Position relative to the button
    const top = buttonRect.top + buttonRect.height + 4; // 4px gap
    const left = buttonRect.right - menuWidth;

    setMenuPosition({ top, left });
  }, [isMenuOpen]);

  useEffect(() => {
    updateMenuPosition();
    window.addEventListener('scroll', updateMenuPosition);
    window.addEventListener('resize', updateMenuPosition);

    return () => {
      window.removeEventListener('scroll', updateMenuPosition);
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, [isMenuOpen, updateMenuPosition]);

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
    // Update position immediately on open
    if (!isMenuOpen) {
      setTimeout(updateMenuPosition, 0);
    }
  };

  const togglePopover = (status: 'total' | 'attended') => (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsPopoverOpen(!isPopoverOpen);
    setSelectedStatus(status);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
    setSelectedStatus(null);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const getStatusTitle = () => {
    switch (selectedStatus) {
      case 'total':
        return 'Total Participants';
      case 'attended':
        return 'Attended Participants';
      default:
        return 'All Participants';
    }
  };

  const stats = {
    total: meetingInfo.attendees.length,
    attended: meetingInfo.attendees.filter(a => a.didAttend).length,
    notAttended: meetingInfo.attendees.filter(a => !a.didAttend).length,
    accepted: meetingInfo.attendees.filter(a => a.didAccept).length
  };

  return (
    <div className="relative group">
      <div
        className="bg-white rounded-lg border border-gray-400 shadow-sm hover:border-gray-300 hover:shadow-md transition-all duration-300 overflow-hidden flex flex-col h-full min-w-[200px]"
      >
        <div className="relative flex flex-1">
          {/* Progress Bar */}
          <div className="absolute inset-y-0 left-0 w-1">
            <div className="absolute inset-0 bg-gray-100">
              <div
                className="relative bottom-0 top-0 left-0 right-0 bg-blue-500"
                style={{
                  height: `85%`
                }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 pl-3 pr-1 pt-1 pb-1">
            <div className="flex justify-between items-start">
              <div className="flex-grow pr-2">
                <h2 className="text-xs font-bold text-gray-800 mb-1 line-clamp-1">
                  {meetingInfo.interestName}
                </h2>
                <div className="space-y-0.5">
                  <p className="text-xs flex items-center text-gray-600">
                    <Calendar size={12} className="mr-1 flex-shrink-0" />
                    {formatDate(meetingInfo.meetingStartDateTime)}
                  </p>
                  <p className="text-xs flex items-center text-gray-600 whitespace-nowrap">
                    <Clock size={12} className="mr-1 flex-shrink-0" />
                    {formatTime(meetingInfo.meetingStartDateTime)} - {formatTime(meetingInfo.meetingEndDateTime)}
                    <span>
                      <button
                        onClick={togglePopover('total')}
                        className="absolute text-xs flex items-center ml-2 bottom-1 bg-blue-50 text-blue-600 px-2 py-0.5 rounded hover:bg-blue-100 transition-colors"
                      >
                        <Users size={12} className="mr-1" />
                        <span className="font-medium">{stats.total}</span>
                      </button>
                    </span>
                  </p>

                </div>
              </div>

              <div className="relative">
                <button
                  ref={buttonRef}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200 text-gray-500"
                  onClick={toggleMenu}
                  aria-label="More options"
                  aria-expanded={isMenuOpen}
                  aria-haspopup="true"
                >
                  <MoreVertical size={16} />
                </button>
                {isMenuOpen && createPortal(
                  <div
                    ref={menuRef}
                    style={{
                      position: 'fixed',
                      top: `${menuPosition.top}px`,
                      left: `${menuPosition.left + 50}px`,
                      zIndex: 9999,
                      maxHeight: '300px',
                      overflowY: 'auto',
                      transform: 'translateZ(0)'
                    }}
                    className="bg-white rounded-lg shadow-lg border border-gray-500 w-[140px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <DownloadMenu
                      meetingInfo={meetingInfo}
                      onClose={() => setIsMenuOpen(false)}
                      isAvatarGroupMenu={true}
                    />
                  </div>,
                  document.body
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Footer Section with Stats */}
        <div className="border-t border-gray-100 bg-gray-50">
          <div className="px-3 py-0.5 flex items-center justify-center space-x-3 border bg-gray-100">
          </div>
        </div>
      </div>

      {isPopoverOpen && (
        <AvatarGroupPopover
          attendees={meetingInfo.attendees}
          onClose={handleClose}
          companyId={meetingInfo.companyId}
          interestId={meetingInfo.interestId}
          interestName={`${meetingInfo.interestName} - ${getStatusTitle()}`}
          stats={stats}
          initialFilter={selectedStatus === 'attended' ? 'attended' : 'total'}
        />
      )}
    </div>
  );
};

export default AvatarGroup;