import React, { useState } from 'react';
import { IMeetingInfo } from '../../types';
import AvatarGroup from './AvatarGroup';
import { ArrowDown } from 'lucide-react';
import { motion } from 'framer-motion';

interface CardViewProps {
  meetings: IMeetingInfo[];
}

const INITIAL_LOAD = 20;
const LOAD_MORE_COUNT = 10;

const CardView: React.FC<CardViewProps> = ({ meetings }) => {
  const [displayCount, setDisplayCount] = useState(INITIAL_LOAD);

  const loadMore = () => {
    setDisplayCount(prev => Math.min(prev + LOAD_MORE_COUNT, meetings.length));
  };

  // Sort meetings by topic name in ascending order, numbers last
  const sortedMeetings = [...meetings].sort((a, b) => {
    const topicA = a.interestName?.toLowerCase() || '';
    const topicB = b.interestName?.toLowerCase() || '';

    // Check if either topic starts with a number
    const aStartsWithNumber = /^\d/.test(topicA);
    const bStartsWithNumber = /^\d/.test(topicB);

    // If one starts with number and other doesn't, put number last
    if (aStartsWithNumber && !bStartsWithNumber) return 1;
    if (!aStartsWithNumber && bStartsWithNumber) return -1;

    // If both start with number or both don't, sort alphabetically
    return topicA.localeCompare(topicB);
  });

  const displayedMeetings = sortedMeetings.slice(0, displayCount);
  const hasMore = displayCount < meetings.length;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      className="space-y-6"
    >
      <div className="grid gap-x-9 gap-y-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 p-1">
        {displayedMeetings.map((meeting, index) => (
          <motion.div
            key={meeting.meetingId}
            className="transform-gpu"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
          >
            <AvatarGroup
              meetingInfo={meeting}
              colorIndex={index}
            />
          </motion.div>
        ))}
      </div>

      {hasMore && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMore}
            className="group bg-indigo-500 hover:bg-indigo-600 text-white px-2 py-1.5 rounded-full shadow-sm transition-all duration-200 text-xs"
          >
            <div className="flex items-center justify-center space-x-1.5">
              <div className="bg-white/20 px-1.5 py-0.5 rounded-full">
                {displayCount} / {meetings.length}
              </div>
              <span className="font-medium">Load More 10</span>
              <ArrowDown
                size={12}
                className="transform transition-transform duration-200 group-hover:translate-y-0.5"
              />
            </div>
          </button>
        </div>
      )}
    </motion.div>
  );
};

export default CardView;