import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export interface LeaderboardData {
  rank: number;
  name: string;
  group: string;
  acceptedScore: string;
  participationScore: string;
  acceptedButNotParticipated: string;
  aARatio: string;
  engagementScore: string;
}

interface FormattedLeaderboardData {
  rank: number;
  name: string;
  group: string;
  acceptedScore: string;
  participationScore: string;
  acceptedButNotParticipated: string;
  aARatio: string;
  engagementScore: string;
}

interface LeaderboardMetrics {
  totalEvents: number;
  totalParticipants: number;
  avgAcceptance: number;
  avgAttendance: number;
}

interface LeaderboardExportData {
  title: string;
  summary: LeaderboardMetrics;
  data: LeaderboardData[];
}

const getFormattedDate = () => {
  const now = new Date();
  return now.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

const getFileName = (format: string) => {
  const date = new Date().toISOString().split('T')[0];
  return `leaderboard-report_${date}.${format.toLowerCase()}`;
};

const formatDataForExport = (data: LeaderboardData[], userMetrics: LeaderboardData[] = []): FormattedLeaderboardData[] => {
  return data.map(item => ({
    rank: item.rank,
    name: item.name,
    group: item.group,
    acceptedScore: item.acceptedScore,
    participationScore: item.participationScore,
    acceptedButNotParticipated: item.acceptedButNotParticipated,
    aARatio: item.aARatio,
    engagementScore: item.engagementScore
  }));
};

export const exportLeaderboardToPDF = async (
  data: LeaderboardData[], 
  userMetrics: LeaderboardData[] = [],
  metrics: LeaderboardMetrics
) => {
  try {
    const formattedData = formatDataForExport(data, userMetrics);
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    // Add title and metadata with reduced spacing
    doc.setFontSize(24);
    doc.setTextColor(41, 98, 255); // Blue color
    doc.text('Attendance Leaderboard Report', 15, 20);
    
    doc.setFontSize(11);
    doc.setTextColor(100);
    doc.text(`Generated on: ${getFormattedDate()}`, 15, 30);
    doc.text(`Total Records: ${data.length}`, 15, 35);

    // Add metrics summary with blue gradient box - smaller height and moved up
    const blueGradient = doc.setFillColor(219, 234, 254); // bg-blue-100
    doc.rect(15, 38, 260, 20, 'F');
    
    // Add metrics text
    doc.setTextColor(29, 78, 216); // text-blue-700
    doc.setFontSize(11);
    doc.text('Summary Metrics:', 20, 45);
    
    // Position metrics with tighter spacing
    const metricsY = 52;
    const startX = 20;
    const spacing = 65;
    
    // Position metrics
    doc.text(`Total Events: ${metrics.totalEvents}`, startX, metricsY);
    doc.text(`Total Participants: ${metrics.totalParticipants}`, startX + spacing, metricsY);
    doc.text(`Average Acceptance: ${metrics.avgAcceptance}%`, startX + (spacing * 2), metricsY);
    doc.text(`Average Attendance: ${metrics.avgAttendance}%`, startX + (spacing * 3), metricsY);

    // Add pipe symbols between metrics with better spacing
    doc.setTextColor(29, 78, 216); // Keep same blue color
    const pipeY = metricsY;
    const pipeOffset = 5; // Smaller offset for better spacing
    doc.text('|', startX + spacing - pipeOffset, pipeY);
    doc.text('|', startX + (spacing * 2) - pipeOffset, pipeY);
    doc.text('|', startX + (spacing * 3) - pipeOffset, pipeY);

    // Add table headers with reduced top margin
    const startY = 65; // Reduced from previous value
    const columns = [
      { header: 'Rank', dataKey: 'rank' },
      { header: 'Name', dataKey: 'name' },
      { header: 'Group', dataKey: 'group' },
      { header: 'Accepted Score', dataKey: 'acceptedScore' },
      { header: 'Participation Score', dataKey: 'participationScore' },
      { header: 'Accepted But Not Participated', dataKey: 'acceptedButNotParticipated' },
      { header: 'A/A Ratio', dataKey: 'aARatio' },
      { header: 'Engagement Score', dataKey: 'engagementScore' }
    ];

    autoTable(doc, {
      startY: startY,
      head: [columns.map(col => col.header)],
      body: formattedData.map((row, index) => {
        const rank = parseInt(String(row.rank));
        const rowStyle = {
          fillColor: (() => {
            if (rank === 1) return [253, 230, 138] as [number, number, number];      // Light amber for rank 1
            if (rank === 2) return [226, 232, 240] as [number, number, number];      // silver for rank 2
            return index % 2 === 0 ? [249, 250, 251] as [number, number, number] : [245, 245, 245] as [number, number, number]; // Alternate white and light gray
          })()
        };

        return [
          { content: row.rank, styles: rowStyle },
          { content: row.name, styles: rowStyle },
          { content: row.group, styles: rowStyle },
          { content: row.acceptedScore, styles: rowStyle },
          { content: row.participationScore, styles: rowStyle },
          { content: row.acceptedButNotParticipated, styles: rowStyle },
          { content: row.aARatio, styles: rowStyle },
          {
            content: row.engagementScore,
            styles: {
              ...rowStyle,
              textColor: (() => {
                const score = parseFloat(String(row.engagementScore).replace('%', ''));
                if (score >= 90) return [0, 128, 0] as [number, number, number];      // Green
                if (score >= 70) return [0, 0, 255] as [number, number, number];      // Blue
                return [255, 0, 0] as [number, number, number];                       // Red
              })(),
              fontStyle: 'bold'
            }
          }
        ];
      }),
      theme: 'grid',
      styles: {
        fontSize: 9,
        cellPadding: 2,
        lineWidth: 0.3,
        lineColor: [220, 220, 220] as [number, number, number],
        textColor: [0, 0, 0] as [number, number, number],
        minCellHeight: 4
      },
      headStyles: { 
        fillColor: [41, 98, 255] as [number, number, number], 
        textColor: [255, 255, 255] as [number, number, number],
        fontSize: 10,
        fontStyle: 'bold',
        cellPadding: { top: 1.5, bottom: 1.5, left: 4, right: 4 },
        halign: 'center',
        minCellHeight: 6,
        lineWidth: 0.3,
        lineColor: [220, 220, 220] as [number, number, number]
      },
      columnStyles: {
        0: { cellWidth: 20, halign: 'center' },
        1: { cellWidth: 40, halign: 'left' },
        2: { cellWidth: 40, halign: 'left' },
        3: { cellWidth: 30, halign: 'center' },
        4: { cellWidth: 30, halign: 'center' },
        5: { cellWidth: 45, halign: 'center' },
        6: { cellWidth: 25, halign: 'center' },
        7: { cellWidth: 30, halign: 'center' }
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245] as [number, number, number]
      },
      didDrawPage: function(data) {
        const totalPages = Math.ceil(formattedData.length / 20);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text(
          `Page ${data.pageNumber} of ${totalPages}`,
          15,
          doc.internal.pageSize.height - 10,
          { align: 'left' }
        );
      },
      showFoot: 'lastPage'
    });

    const fileName = getFileName('pdf');
    doc.save(fileName);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
};

export const exportLeaderboardToExcel = async (
  data: LeaderboardData[], 
  userMetrics: LeaderboardData[] = [],
  metrics: LeaderboardMetrics
) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Attendance Leaderboard');

    // Set column widths
    worksheet.columns = [
      { width: 9 },   // Rank
      { width: 20 },  // Name
      { width: 25 },  // Group
      { width: 15 },  // Accepted Score
      { width: 18 },  // Participation Score
      { width: 28 },  // Accepted But Not Participated
      { width: 12 },  // A/A Ratio
      { width: 18 },  // Engagement Score
    ];

    // Add title
    worksheet.mergeCells('A1:H1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = 'Attendance Leaderboard Report';
    titleCell.font = { size: 20, color: { argb: '4169E1' }, bold: true };
    titleCell.alignment = { horizontal: 'left' };

    // Add metadata
    worksheet.getCell('A2').value = `Generated on: ${getFormattedDate()}`;
    worksheet.getCell('A3').value = `Total Records: ${data.length}`;
    worksheet.getCell('A2').font = { size: 11, color: { argb: '666666' } };
    worksheet.getCell('A3').font = { size: 11, color: { argb: '666666' } };

    // Add metrics summary with blue background
    worksheet.mergeCells('A4:H4');
    const metricsCell = worksheet.getCell('A4');
    metricsCell.value = `Summary Metrics: Total Events: ${metrics.totalEvents} | Total Participants: ${metrics.totalParticipants} | Average Acceptance: ${metrics.avgAcceptance}% | Average Attendance: ${metrics.avgAttendance}%`;
    metricsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DBEAFE' }
    };
    metricsCell.font = { color: { argb: '1D4ED8' }, size: 11 };
    metricsCell.alignment = { horizontal: 'left', vertical: 'middle' };

    // Add empty row after metrics
    worksheet.addRow([]);

    // Add headers
    const headers = [
      'Rank', 'Name', 'Group', 'Accepted Score', 'Participation Score',
      'Accepted But Not Participated', 'A/A Ratio', 'Engagement Score'
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4169E1' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Set header alignments
    headerRow.getCell(1).alignment = { horizontal: 'center' };
    headerRow.getCell(2).alignment = { horizontal: 'left' };
    headerRow.getCell(3).alignment = { horizontal: 'left' };
    for(let i = 4; i <= 8; i++) {
      headerRow.getCell(i).alignment = { horizontal: 'center' };
    }

    // Add data rows
    const formattedData = formatDataForExport(data, userMetrics);
    const parsePercentage = (value: string | number): number => {
      if (typeof value === 'string') {
        return Number(value.replace('%', '')) / 100;
      }
      return Number(value) / 100;
    };

    formattedData.forEach((item, index) => {
      const row = worksheet.addRow([
        item.rank,
        item.name,
        item.group,
        parsePercentage(item.acceptedScore),
        parsePercentage(item.participationScore),
        parsePercentage(item.acceptedButNotParticipated),
        parsePercentage(item.aARatio),
        parsePercentage(item.engagementScore)
      ]);

      // Style each cell in the row
      row.eachCell((cell, colNumber) => {
        // Format percentage columns
        if (colNumber >= 4) {
          cell.numFmt = '0%';
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };

        // Set alignment based on column
        if (colNumber === 1) {
          cell.alignment = { horizontal: 'center' };
        } else if (colNumber === 2 || colNumber === 3) {
          cell.alignment = { horizontal: 'left' };
        } else {
          cell.alignment = { horizontal: 'center' };
        }

        // Make engagement score bold
        if (colNumber === 8) {
          cell.font = { bold: true };
        }
      });

      // Add row colors based on rank
      if (item.rank === 1) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FDE68A' } // Light amber (253, 230, 138)
          };
        });
      } else if (item.rank === 2) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E2E8F0' } // Silver (226, 232, 240)
          };
        });
      } else if (index % 2 === 1) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F9FAFB' } // Light gray for alternating rows
          };
        });
      }

      // Style engagement score with color based on value
      const score = parsePercentage(item.engagementScore) * 100;
      const engagementCell = row.getCell(8);
      engagementCell.font = {
        bold: true,
        color: { argb: score >= 90 ? '008000' : score >= 70 ? '0000FF' : 'FF0000' }
      };
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getFileName('xlsx'));
  } catch (error) {
    console.error('Error generating Excel:', error);
    throw new Error('Failed to generate Excel file');
  }
};

export const exportLeaderboardToCSV = async (
  data: LeaderboardData[], 
  userMetrics: LeaderboardData[] = [],
  metrics: LeaderboardMetrics
) => {
  try {
    const formattedData = formatDataForExport(data, userMetrics);
    
    // Create CSV content
    let csvContent = 'Attendance Leaderboard Report\n';
    csvContent += `Generated on: ${getFormattedDate()}\n`;
    csvContent += `Total Records: ${data.length}\n\n`;
    csvContent += `Summary Metrics:\n`;
    csvContent += `Total Events: ${metrics.totalEvents} | Total Participants: ${metrics.totalParticipants} | Average Acceptance: ${metrics.avgAcceptance}% | Average Attendance: ${metrics.avgAttendance}%\n\n`;
    
    // Add headers
    csvContent += 'Rank,Name,Group,Accepted Score,Participation Score,Accepted But Not Participated,A/A Ratio,Engagement Score\n';
    
    // Add data rows
    formattedData.forEach(row => {
      csvContent += `${row.rank},${row.name},${row.group},${row.acceptedScore},${row.participationScore},${row.acceptedButNotParticipated},${row.aARatio},${row.engagementScore}\n`;
    });
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, getFileName('csv'));
  } catch (error) {
    console.error('Error generating CSV:', error);
    throw new Error('Failed to generate CSV file');
  }
};

export const exportLeaderboardToJSON = async (
  data: LeaderboardData[],
  userMetrics: LeaderboardData[] = [],
  metrics: LeaderboardMetrics
) => {
  try {
    const formattedData = formatDataForExport(data, userMetrics);
    const exportData: LeaderboardExportData = {
      title: 'Attendance Leaderboard Report',
      summary: {
        totalEvents: metrics.totalEvents,
        totalParticipants: metrics.totalParticipants,
        avgAcceptance: metrics.avgAcceptance,
        avgAttendance: metrics.avgAttendance
      },
      data: formattedData
    };
    
    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    saveAs(blob, getFileName('json'));
  } catch (error) {
    console.error('Error generating JSON:', error);
    throw new Error('Failed to generate JSON file');
  }
};
