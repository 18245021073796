import React from 'react';
import { Crown, Trophy, Medal } from 'lucide-react';
import { motion } from 'framer-motion';

interface RankBadgeProps {
  rank: number;
  badgeRank?: number;
}

const RankBadge: React.FC<RankBadgeProps> = ({ rank, badgeRank = 0 }) => {
  // Only show badges for rank 1 and 2 when badgeRank is provided
  if (rank > 2 || badgeRank === 0) {
    return <span className="text-gray-600">{rank}</span>;
  }

  const getBadgeContent = () => {
    switch (badgeRank) {
      case 1: // 100% - Gold Crown
        return {
          icon: <Crown className="w-5 h-5 text-yellow-500" />,
          color: 'bg-gradient-to-br from-yellow-50 to-yellow-100',
          animation: {
            rotate: [-8, 8, -8],
            y: [-1, 1, -1],
            transition: {
              duration: 0.3,
              repeat: Infinity,
              ease: "linear"
            }
          }
        };
      case 2: // 96% - Silver Trophy
        return {
          icon: <Trophy className="w-5 h-5 text-slate-500" />,
          color: 'bg-gradient-to-br from-slate-100 to-slate-200',
          animation: {
            rotate: [-5, 5, -5],
            y: [-1, 1, -1],
            transition: {
              duration: 0.35,
              repeat: Infinity,
              ease: "linear"
            }
          }
        };
      default:
        return {
          icon: null,
          color: '',
          animation: {}
        };
    }
  };

  const { icon, color, animation } = getBadgeContent();
  const showBadge = badgeRank > 0;

  return (
    <div className="flex items-center justify-center w-full">
      {showBadge ? (
        <motion.div
          className={`relative flex items-center justify-center w-10 h-10 rounded-full shadow-md ${color} hover:shadow-lg`}
          whileHover={{ scale: 1.1 }}
          animate={animation}
        >
          {icon}
          <div className="absolute -top-1 -right-1 bg-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold shadow-sm ring-1 ring-gray-200">
            <span className="text-gray-700">{rank}</span>
          </div>
        </motion.div>
      ) : (
        <span className="text-base font-semibold text-gray-600">{rank}</span>
      )}
    </div>
  );
};

export default RankBadge;