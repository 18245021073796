import { Chart as ChartJS, ChartOptions, LegendItem, Element } from 'chart.js';
import { IMeetingInfo, DatasetType, GroupedData, CustomChartData, CustomDataset } from '../../../types';
import { CHART_COLORS } from './colors';

interface Statistics {
  totalParticipants: number;
  totalAttended: number;
  totalNotAttended: number;
  attendedPercentage: number;
  notAttendedPercentage: number;
}

// Extended Element type that includes hidden property
interface ExtendedElement extends Element {
  hidden?: boolean;
}

const calculateOverallStatistics = (meetings: IMeetingInfo[]): Statistics => {
  const totalParticipants = meetings.reduce(
    (sum, meeting) => sum + meeting.attendees.length,
    0
  );
  const totalAttended = meetings.reduce(
    (sum, meeting) =>
      sum + meeting.attendees.filter((a) => a.didAttend).length,
    0
  );
  const totalNotAttended = totalParticipants - totalAttended;

  return {
    totalParticipants,
    totalAttended,
    totalNotAttended,
    attendedPercentage: (totalAttended / totalParticipants) * 100,
    notAttendedPercentage: (totalNotAttended / totalParticipants) * 100,
  };
};

const groupMeetingsByTopic = (meetings: IMeetingInfo[]): GroupedData[] => {
  const groupedMeetings = meetings.reduce((acc, meeting) => {
    if (!acc[meeting.interestName]) {
      acc[meeting.interestName] = {
        topic: meeting.interestName,
        totalParticipants: 0,
        attended: 0,
        notAttended: 0,
        accepted: 0,
      };
    }

    const attendees = meeting.attendees;
    acc[meeting.interestName].totalParticipants += attendees.length;
    acc[meeting.interestName].attended += attendees.filter(
      (a) => a.didAttend
    ).length;
    acc[meeting.interestName].notAttended += attendees.filter(
      (a) => !a.didAttend
    ).length;
    acc[meeting.interestName].accepted += attendees.filter(
      (a) => a.didAccept
    ).length;

    return acc;
  }, {} as Record<string, GroupedData>);

  return Object.values(groupedMeetings);
};

export const createBarData = (meetings: IMeetingInfo[]): CustomChartData => {
  const groupedData = groupMeetingsByTopic(meetings);
  // Custom sorting function to handle topics starting with numbers
  const sortedGroupedData = [...groupedData].sort((a, b) => {
    const aStartsWithNumber = /^\d/.test(a.topic);
    const bStartsWithNumber = /^\d/.test(b.topic);

    // If both topics start with numbers or both don't, sort alphabetically
    // For topics starting with numbers, use the second word for comparison
    if (aStartsWithNumber === bStartsWithNumber) {
      if (aStartsWithNumber) {
        const aSecondWord = a.topic.split(/\s+/)[1] || '';
        const bSecondWord = b.topic.split(/\s+/)[1] || '';
        return aSecondWord.localeCompare(bSecondWord);
      }
      return a.topic.localeCompare(b.topic);
    }

    // If only one topic starts with a number, put it at the end
    return aStartsWithNumber ? 1 : -1;
  });
  const labels = sortedGroupedData.map((group) => group.topic);

  return {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Accepted',
        data: sortedGroupedData.map(
          (group) => (group.accepted / group.totalParticipants) * 100
        ),
        backgroundColor: CHART_COLORS.accepted.bg,
        borderColor: CHART_COLORS.accepted.border,
        borderWidth: 1,
        stack: 'Stack 0',
        _grouped: sortedGroupedData,
        hidden: false,
      },
      {
        type: 'bar',
        label: 'Attended',
        data: sortedGroupedData.map(
          (group) => (group.attended / group.totalParticipants) * 100
        ),
        backgroundColor: CHART_COLORS.attended.bg,
        borderColor: CHART_COLORS.attended.border,
        borderWidth: 1,
        stack: 'Stack 1',
        _grouped: sortedGroupedData,
        hidden: false,
      },
      {
        type: 'bar',
        label: 'Not Attended',
        data: sortedGroupedData.map(
          (group) => (group.notAttended / group.totalParticipants) * 100
        ),
        backgroundColor: CHART_COLORS.notAttended.bg,
        borderColor: CHART_COLORS.notAttended.border,
        borderWidth: 1,
        stack: 'Stack 2',
        _grouped: sortedGroupedData,
        hidden: true,
      },
    ],
  };
};

export const createBarOptions = (
  meetings: IMeetingInfo[]
): ChartOptions<'bar'> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Attendance and Acceptance Rates Per Topic (%)',
        font: { size: 16, weight: 'bold' },
        padding: 20,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const topic = context[0].label;
            const dataset = context[0].dataset as CustomDataset;
            const groupedData = dataset._grouped?.find(
              (g) => g.topic === topic
            );
            return [
              topic,
              `Total Invited(Participants): ${groupedData?.totalParticipants ?? 0}`,
            ];
          },
          label: (context) => {
            const value = context.raw as number;
            const datasetLabel = context.dataset.label || '';
            const dataset = context.dataset as CustomDataset;
            const groupedData = dataset._grouped?.[context.dataIndex];

            if (!groupedData) return '';

            if (datasetLabel === 'Attended') {
              return `${datasetLabel}: ${groupedData.attended} (${value.toFixed(
                1
              )}%)`;
            } else if (datasetLabel === 'Accepted') {
              return `${datasetLabel}: ${groupedData.accepted} (${value.toFixed(
                1
              )}%)`;
            } else if (datasetLabel === 'Not Attended') {
              return `${datasetLabel}: ${
                groupedData.notAttended
              } (${value.toFixed(1)}%)`;
            } else if (datasetLabel === 'Total Participants') {
              return `${datasetLabel}: ${
                groupedData.totalParticipants
              } (${value.toFixed(1)}%)`;
            }
            return '';
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          font: { size: 12 },
          filter: (legendItem) => {
            return (
              legendItem.text === 'Accepted' ||
              legendItem.text === 'Attended' ||
              legendItem.text === 'Not Attended' ||
              legendItem.text === 'Total Participants'
            );
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Topics',
          font: { size: 12, weight: 'bold' },
          padding: 20,
        },
        stacked: true,
        grid: { display: false },
        ticks: { maxRotation: 45, minRotation: 45 },
      },
      y: {
        title: {
          display: true,
          text: 'Attendance and Acceptance Rates Per Topic (%)',
          font: { size: 12, weight: 'bold' },
          padding: 20,
        },
        stacked: true,
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
    animation: {
      duration: 1500,
      easing: 'easeInOutQuart',
    },
  };
};

export const createPieData = (meetings: IMeetingInfo[]) => {
  const stats = calculateOverallStatistics(meetings);

  const data = [stats.totalAttended, stats.totalNotAttended];
  const labels = [
    `Attended: ${stats.totalAttended} (${stats.attendedPercentage.toFixed(
      1
    )}%)`,
    `Not Attended: ${
      stats.totalNotAttended
    } (${stats.notAttendedPercentage.toFixed(1)}%)`,
  ];
  const backgroundColor = [
    CHART_COLORS.attended.bg,
    CHART_COLORS.notAttended.bg,
  ];
  const borderColor = [
    CHART_COLORS.attended.border,
    CHART_COLORS.notAttended.border,
  ];

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        borderColor,
        borderWidth: 1,
        hoverBackgroundColor: backgroundColor.map((color) =>
          color.replace('0.8', '1')
        ),
        hoverBorderWidth: 2,
      },
    ],
  };
};

export const createPieOptions = (
  meetings: IMeetingInfo[]
): ChartOptions<'pie'> => {
  const stats = calculateOverallStatistics(meetings);

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: [
          'Overall Attendance Statistics',
          `Total Invited: ${stats.totalParticipants}`,
        ],
        font: { size: 16, weight: 'bold' },
        padding: 20,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw as number;
            const percentage = (
              (value / stats.totalParticipants) *
              100
            ).toFixed(1);
            const type = context.label?.split(':')[0];
            return `${type}: ${value} out of ${stats.totalParticipants} (${percentage}%)`;
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          generateLabels: (chart): LegendItem[] => {
            const data = chart.data;
            if (data.labels?.length && data.datasets.length) {
              return data.labels.map((label, index) => {
                const dataset = data.datasets[0];
                const backgroundColor = dataset.backgroundColor as string[];
                const borderColor = dataset.borderColor as string[];
                const value = dataset.data[index];
                const percentage = (
                  ((value as number) / stats.totalParticipants) *
                  100
                ).toFixed(1);
                const type = (label as string).split(':')[0];

                return {
                  text: `${type}: ${value} (${percentage}%)`,
                  fillStyle: backgroundColor[index],
                  strokeStyle: borderColor[index],
                  lineWidth: 1,
                  hidden: false,
                  index,
                  datasetIndex: 0,
                };
              });
            }
            return [];
          },
          padding: 20,
          font: { size: 12 },
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.index;
          const ci = legend.chart;
          if (ci && typeof index === 'number') {
            const meta = ci.getDatasetMeta(0);
            if (meta.data[index]) {
              const element = meta.data[index] as ExtendedElement;
              const alreadyHidden = element.hidden || false;
              element.hidden = !alreadyHidden;
              ci.update();
            }
          }
        },
      },
    },
    animation: {
      duration: 1500,
      easing: 'easeInOutQuart',
    },
  };
};