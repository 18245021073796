import { IMeetingInfo, IAttendeeInfo } from '../types';

const generateRandomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const generateAttendee = (id: string, forceNotAttended: boolean = false, forceNotAccepted: boolean = false): IAttendeeInfo => {
  const firstNames = [
    'Alice', 'Bob', 'Charlie', 'David', 'Eva', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack',
    'Katherine', 'Liam', 'Mia', 'Noah', 'Olivia', 'Peter', 'Quinn', 'Rachel', 'Samuel', 'Tara',
    'Uma', 'Victor', 'Wendy', 'Xavier', 'Yara', 'Zack', 'Anna', 'Benjamin', 'Clara', 'Daniel',
    'Emma', 'Felix', 'Georgia', 'Hugo', 'Isabel', 'James', 'Kylie', 'Lucas', 'Maya', 'Nathan'
  ];
  const lastNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
    'Anderson', 'Taylor', 'Thomas', 'Moore', 'Jackson', 'Martin', 'Lee', 'Thompson', 'White', 'Harris',
    'Clark', 'Lewis', 'Robinson', 'Walker', 'Young', 'King', 'Wright', 'Scott', 'Green', 'Baker',
    'Adams', 'Nelson', 'Carter', 'Mitchell', 'Parker', 'Collins', 'Morris', 'Murphy', 'Cook', 'Rogers'
  ];
  const name = `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${lastNames[Math.floor(Math.random() * lastNames.length)]}`;
  const email = `${name.toLowerCase().replace(' ', '.')}@example.com`;
  const didAttend = forceNotAttended ? false : Math.random() > 0.2;
  const didAccept = forceNotAccepted ? false : Math.random() > 0.1;
  const joinDateTime = generateRandomDate(new Date(2024, 0, 1), new Date(2024, 11, 31));
  const leaveDateTime = new Date(joinDateTime.getTime() + Math.random() * 3600000);
  const totalAttendanceInSeconds = Math.floor((leaveDateTime.getTime() - joinDateTime.getTime()) / 1000).toString();

  return {
    attendeeId: id,
    attendeeName: name,
    attendeeEmail: email,
    didAttend,
    didAccept,
    attendeeJoinDateTime: joinDateTime.toISOString(),
    attendeeLeaveDateTime: leaveDateTime.toISOString(),
    totalAttendanceInSeconds,
  };
};

export const generateMeetingData = (count: number): IMeetingInfo[] => {
  const meetings: IMeetingInfo[] = [];
  const interests = [
    'Blockchain', 'Artificial Intelligence', 'Machine Learning', 'Internet of Things', 'Cloud Computing',
    'Cybersecurity', 'Data Science', 'DevOps Practices', 'Web Development', 'Mobile Development',
    'Quantum Computing', 'Augmented Reality', 'Virtual Reality', 'Edge Computing', '5G Networks',
    'Robotics', 'Natural Language Processing', 'Computer Vision', 'Big Data Analytics', 'Digital Transformation',
    'UI/UX Design', 'Microservices Architecture', 'Serverless Computing', 'Sustainable Technology', 'FinTech',
    'HealthTech', 'EdTech', 'Green Technology', 'Smart Cities', 'Autonomous Systems',
    'Blockchain DeFi', 'Cloud Native', 'API Development', 'Software Testing', 'Project Management',
    'Agile Methodologies', 'Digital Marketing', 'E-commerce Solutions', 'Network Security', 'Data Privacy'
  ];

  // Create a map to track unique attendees across all meetings
  const attendeeTracker = new Map<string, {
    name: string,
    email: string,
    group: string,
    attendancePattern: number // 0-100, higher means more likely to attend/accept
  }>();

  // Pre-generate some fixed attendees to ensure consistent participation
  const firstNames = [
    'Alice', 'Bob', 'Charlie', 'David', 'Eva', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack',
    // 'Katherine', 'Liam', 'Mia', 'Noah', 'Olivia', 'Peter', 'Quinn', 'Rachel', 'Samuel', 'Tara',
    // 'Uma', 'Victor', 'Wendy', 'Xavier', 'Yara', 'Zack', 'Anna', 'Benjamin', 'Clara', 'Daniel',
    // 'Emma', 'Felix', 'Georgia', 'Hugo', 'Isabel', 'James', 'Kylie', 'Lucas', 'Maya', 'Nathan',
    // 'Oscar', 'Penny', 'Quincy', 'Ruby', 'Simon', 'Tina', 'Ulysses', 'Violet', 'Walter', 'Xena',
    // 'Yuri', 'Zara', 'Adrian', 'Bella', 'Cameron', 'Diana', 'Ethan', 'Fiona', 'George', 'Hannah'
  ];
  const lastNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
    // 'Anderson', 'Taylor', 'Thomas', 'Moore', 'Jackson', 'Martin', 'Lee', 'Thompson', 'White', 'Harris',
    // 'Clark', 'Lewis', 'Robinson', 'Walker', 'Young', 'King', 'Wright', 'Scott', 'Green', 'Baker',
    // 'Adams', 'Nelson', 'Carter', 'Mitchell', 'Parker', 'Collins', 'Morris', 'Murphy', 'Cook', 'Rogers',
    // 'Morgan', 'Cooper', 'Peterson', 'Bailey', 'Reed', 'Kelly', 'Howard', 'Ramos', 'Kim', 'Cox',
    // 'Ward', 'Richardson', 'Watson', 'Brooks', 'Chavez', 'Wood', 'James', 'Bennett', 'Gray', 'Price'
  ];

  const groups = [
    // Regional Engineering Teams
    'Engineering - APAC',
    'Engineering - EMEA',
    'Engineering - NA',
    'Engineering - LATAM',
    
    // Regional Product Teams
    'Product - APAC',
    'Product - EMEA',
    'Product - NA',
    'Product - LATAM',
    
    // Regional Sales Teams
    'Sales - APAC',
    'Sales - EMEA',
    'Sales - NA',
    'Sales - LATAM',
    
    // Regional Support Teams
    'Support - APAC',
    'Support - EMEA',
    'Support - NA',
    'Support - LATAM',
    
    // Regional Marketing Teams
    'Marketing - APAC',
    'Marketing - EMEA',
    'Marketing - NA',
    'Marketing - LATAM',
    
    // Global Teams
    'Global Leadership',
    'Global Finance',
    'Global HR',
    'Global Operations',
    'Global Legal',
    
    // Specialized Teams
    'Data Science',
    'AI Research',
    'Cloud Infrastructure',
    'Security',
    'DevOps',
    'QA',
    'UX/UI Design',
    'Technical Writing',
    'Developer Relations',
    'Customer Success',
    
    // Business Units
    'Enterprise Solutions',
    'SMB Solutions',
    'Government Services',
    'Healthcare Solutions',
    'Financial Services'
  ];

  // Create exactly 120 fixed attendees with different attendance patterns
  const targetAttendeeCount = 120;
  for (let i = 0; i < targetAttendeeCount; i++) {
    const firstName = firstNames[i % firstNames.length];
    const lastName = lastNames[Math.floor(i / firstNames.length)];
    const name = `${firstName} ${lastName}`;
    const email = `${name.toLowerCase().replace(' ', '.')}@example.com`;
    
    // Distribute groups with some weighting
    // More people in regional teams, fewer in specialized/global teams
    let groupIndex;
    if (i < 60) {
      // First 60 people distributed across regional teams (0-19)
      groupIndex = i % 20;
    } else if (i < 80) {
      // Next 20 people in global teams (20-24)
      groupIndex = 20 + ((i - 60) % 5);
    } else if (i < 100) {
      // Next 20 people in specialized teams (25-34)
      groupIndex = 25 + ((i - 80) % 10);
    } else {
      // Last 20 people in business units (35-39)
      groupIndex = 35 + ((i - 100) % 5);
    }
    
    const group = groups[groupIndex];
    
    attendeeTracker.set(email, {
      name,
      email,
      group,
      attendancePattern: Math.floor(Math.random() * 100) // Different attendance patterns for each person
    });
  }

  // Generate exactly 40 meetings
  const targetMeetingCount = 40;
  for (let i = 0; i < targetMeetingCount; i++) {
    const attendees: IAttendeeInfo[] = [];

    // Select attendees from our tracked pool
    const availableAttendees = Array.from(attendeeTracker.values());
    // Shuffle available attendees to get random selection each time
    const shuffledAttendees = availableAttendees
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    // Each meeting will have between 40-80 attendees (33%-66% of total users)
    const attendeesCount = Math.floor(Math.random() * 41) + 40;

    for (let j = 0; j < attendeesCount; j++) {
      const attendee = shuffledAttendees[j];
      const pattern = attendee.attendancePattern;
      
      // Use attendance pattern to determine behavior
      const didAccept = Math.random() * 100 < (pattern + 20); // More likely to accept
      const didAttend = didAccept && (Math.random() * 100 < pattern); // Only attend if accepted, based on pattern

      const startDateTime = generateRandomDate(new Date(2024, 0, 1), new Date(2024, 11, 31));
      const endDateTime = new Date(startDateTime.getTime() + (Math.random() * 2 + 1) * 3600000); // 1-3 hours

      attendees.push({
        attendeeId: `attendee-${i}-${j}`,
        attendeeName: attendee.name,
        attendeeEmail: attendee.email,
        attendeeGroup: attendee.group,
        didAttend,
        didAccept,
        attendeeJoinDateTime: startDateTime.toISOString(),
        attendeeLeaveDateTime: endDateTime.toISOString(),
        totalAttendanceInSeconds: Math.floor((endDateTime.getTime() - startDateTime.getTime()) / 1000).toString(),
      });
    }

    const startDateTime = generateRandomDate(new Date(2024, 0, 1), new Date(2024, 11, 31));
    const endDateTime = new Date(startDateTime.getTime() + (Math.random() * 2 + 1) * 3600000);

    meetings.push({
      attendees,
      companyId: `company${i + 1}`,
      interestId: `interest${i + 1}`,
      interestName: interests[i % interests.length],
      meetingId: `meeting${i + 1}`,
      meetingTimeUtc: startDateTime.toISOString(),
      meetingStartDateTime: startDateTime.toISOString(),
      meetingEndDateTime: endDateTime.toISOString(),
      totalParticipantCount: attendeesCount.toString(),
    });
  }

  return meetings;
};